<template>
    <div class="container">
        <div class="card card-default border-0" v-if="!$store.state.active || ($store.state.active && 'list destination' in $store.state.permissions)">
			<div class="card-header border-0">
				<div class="row">
					<div class="col-6 m-auto">
						<div class="r-fliter search_filter has_icon i-search add__new__sources_search">
							<i class="fa fa-search"></i>
							<div>
								<label for="search" class="sr-only">{{ $t('CRMsSearch')}}</label>
								<input v-model="query" type="search" :placeholder="$t('CrmPSearch')" class="form-control" id="search">
							</div>
						</div>
					</div>
					<div class="col-6">
						<div class="alert alert-oml ml-auto" style="display:none">
							<img src="/img/svg/info-circle.svg" /> {{ $t('InInfoDesc') }} <a href="javascript:void(0)">{{ $t('LetUsknow') }}</a>
						</div>
					</div>
				</div>
			</div>
			
            <div class="card-body">
                
				<div class="row text-center sourceline-item">
					<div class="col-md-3 mb-4" v-for="(item, key) in FavoriteCrm" v-bind:key="key" :id="`zapier-${item.name}`">
						<div class="new-sources-md row m-0">
							<div class="col-md-12">
								<img src="/img/svg/By-Zapier.svg" v-if="item.viaZapier" style="position: absolute;right: 0px;" />
								<img :src="'/img/Integration/crm/' + item.logo" style="width:60px;" />
							</div>
							<div class="col-md-12 p-0">
								<label v-text="item.title" class="m-0 my-auto" style="font-size: 12px;"></label>
								<p class="my-auto">
									{{ item.description }}
								</p>
							</div>
							<div class="col-md-12 m-auto">
								<!-- <button class="btn btn-upgrade" v-if="!$store.state.active || ($store.state.active && 'add integrations' in $store.state.permissions)" @click="ConnectCRM(item)">{{ $t('Connect') }}</button> -->
								<button class="btn btn-upgrade" v-if="item.name=='webhook'">
									<router-link style="color:#fff" :to="{name: 'add-destination'}" class="no-account pull-left">{{ $t('Connect')}}</router-link>
								</button>
								<button class="btn btn-upgrade" v-else @click="ConnectCRM(item)">{{ $t('Connect') }}</button>
							</div>
						</div>
						<!--div class="new-crm-md row m-0 integration">
							<div class="col-md-12">
								<img :src="'/images/Integration/crm/' + item.logo_path" style="width:48%;" />
							</div>
							<div class="col-md-12 p-0">
								<label v-text="item.name" class="m-0 my-auto" style="font-size: 12px;"></label>
							</div>
						</div-->
						<!--div class="new-crm-md row m-0 details">
							<div style="width: 100%;">
								<div class="d-flex justify-content-center align-items-center">
									<img :src="'/images/Integration/crm/' + item.logo_path" style="width: 20px;">
									<h3 class="h5 mb-0" style="font-size: 10px;" v-text="item.name"></h3>
								</div>
								<div class="mt-4">
									<a href="javascript:void(0)" v-for="(option, id) in item.options" v-bind:key="id">
										<span :class="['badge badge-pill badge-native', option.slug == 'native' ? 'badge-secondary' : option.slug == 'zapier' ? 'badge-warning' : 'badge-primary' ]" v-text="option.label"></span>
									</a>
								</div>
							</div>
						</div-->
						
					</div>
					
				</div>
				
            </div>
			
        </div>
			<div v-if="$store.state.active && !Object.keys($store.state.permissions).includes('list destination')">
				<h5 class="sync-text">
					<img src="/img/svg/info-circle.svg" />
					<span style="text-decoration:none"> {{ $t('Noaccess') }}</span>
				</h5>
			</div>
    </div>
</template>

<style lang="scss">
	
	.api-panel-md {
		
		.integration {
			display: block;
		}
		
		.details {
			display: none;
		}
		
		&:hover {
			.integration {
				display: none;
			}
			
			.details {
				display: block;
			}
		}
		
	}
</style>

<script>
	// /assets/new/images/communications_logo
	export default {
		props: {
			favoriteCrm : {}
		},
		data() {
			return {
				//
				query: '',
				
				/* favoriteCrm: [
					{ 
						'name' : 'Pipedrive', 'slug' : 'pipedrive', 'active' : true, 'auth_uri' : '/pipedrive/oauth/pipedrive',
						'logo_path' : 'pipedrive.png', 
						'options' : [
								{ 'label' : 'Native', 'slug' : 'native', 'link' : '/pipedrive/oauth/pipedrive', },
								{ 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' },
								{ 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' }
							], 'integrations' : ['native', 'zapier', 'integromat'] 
					},
					{ 'name' : 'Zoho', 'slug' : 'zoho', 'active' : true, 'auth_uri' : '/integrations/auth/zoho', 'logo_path' : 'zoho.png', 'options' : [ { 'label' : 'Native', 'slug' : 'native', 'link' : '/integrations/auth/zoho', }, { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['native', 'zapier', 'integromat'] },
					{ 'name' : 'Platformly', 'slug' : 'platformly', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'platformly.png', 'form' : { 'access_token' : { 'placeholder' : 'Platformly Api Key', }, }, 'endpoint' : '/api/integrations/platformly', 'options' : [ { 'label' : 'Native', 'slug' : 'native', 'link' : '#', }, { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['native', 'zapier', 'integromat'] },
					{ 'name' : 'SalesFlare', 'slug' : 'salesflare', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'salesflare.png', 'form' : { 'access_token' : { 'placeholder' : 'SalesFlare Api Key', }, }, 'endpoint' : '/api/integrations/salesflare', 'options' : [ { 'label' : 'Native', 'slug' : 'native', 'link' : '#', }, { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['native', 'zapier', 'integromat'] },
					{ 'name' : 'Hubspot', 'slug' : 'hubspot', 'active' : true, 'auth_uri' : '/integrations/auth/hubspot', 'logo_path' : 'hubspot.png', 'options' : [ { 'label' : 'Native', 'slug' : 'native', 'link' : '/integrations/auth/hubspot', }, { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['native', 'zapier', 'integromat'] },
					{ 'name' : 'Salesforce', 'slug' : 'salesforce', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'salesforce.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'FreshsalesCRM', 'slug' : 'freshsales', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'freshsales-crm.png', 'form' : { 'key' : { 'placeholder' : 'API Access Key', }, 'domain' : { 'placeholder' : 'API Access URL', }, }, 'endpoint' : '/api/integrations/crm/freshsales', 'options' : [ { 'label' : 'Native', 'slug' : 'native', 'link' : '#', }, { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['native', 'zapier', 'integromat'] },
					{ 'name' : 'amoCRM', 'slug' : 'amocrm', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'amo-crm.png', 'form' : { 'key' : { 'placeholder' : 'API Access Key', }, 'domain' : { 'placeholder' : 'API Access URL', }, 'login' : { 'placeholder' : 'API Access Login', }, }, 'endpoint' : '/api/integrations/amoCRM', 'options' : [ { 'label' : 'Native', 'slug' : 'native', 'link' : '#', }, { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['native', 'zapier', 'integromat'] },
					{ 'name' : 'Infusionsoft by Keap', 'slug' : 'infusionsoft', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'infusionsoft.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Agile CRM', 'slug' : 'agile', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'agile.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'LionDesk', 'slug' : 'liondesk', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'liondesk.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Insightly', 'slug' : 'insightly', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'insightly.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Streak', 'slug' : 'streak', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'streak.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Copper', 'slug' : 'copper', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'copper.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Close', 'slug' : 'close', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'close.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Redtail CRM', 'slug' : 'redtail', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'redtail.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Zendesk Sell', 'slug' : 'zendesk_sell', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'zendesk_sell.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Contactually', 'slug' : 'contactually', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'contactually.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Highrise', 'slug' : 'highrise', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'highrise.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Microsoft Dynamics 365 CRM', 'slug' : 'microsoft_dynamics', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'microsoft_dynamics.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'WealthBox CRM', 'slug' : 'wealth_box', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'wealth_box.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Kunversion+', 'slug' : 'kunversion', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'kunversion.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Connectwise Manage', 'slug' : 'connectwise_manage', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'connectwise_manage.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Daylite', 'slug' : 'daylite', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'daylite.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Nutshell', 'slug' : 'nutshell', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'nutshell.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Moco', 'slug' : 'moco', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'moco.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Cloze', 'slug' : 'cloze', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'cloze.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'No CRM', 'slug' : 'no_crm', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'no_crm.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'PipelineDeals', 'slug' : 'pipeline_deals', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'pipeline_deals.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'LeadOwl', 'slug' : 'lead_owl', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'lead_owl.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Capsule CRM', 'slug' : 'capsule', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'capsule.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'SugarCRM', 'slug' : 'sugar_crm', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'sugar_crm.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Leadfeeder', 'slug' : 'lead_feeder', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'lead_feeder.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Thryv', 'slug' : 'thryv', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'thryv.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Hatchbuck', 'slug' : 'hatchbuck', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'hatchbuck.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Nimble', 'slug' : 'nimble', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'nimble.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Autotask', 'slug' : 'autotask', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'autotask.jpeg', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'OnepageCRM', 'slug' : 'onepage', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'onepage.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Whatconverts', 'slug' : 'whatconverts', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'whatconverts.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Salesmate', 'slug' : 'salesmate', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'salesmate.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Sellsy', 'slug' : 'sellsy', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'sellsy.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'GreenRope', 'slug' : 'green_rope', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'green_rope.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'SalesRabbit', 'slug' : 'sales_rabbit', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'sales_rabbit.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Bmby', 'slug' : 'bmby', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'bmby.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Moskit', 'slug' : 'moskit', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'moskit.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Prospect.io', 'slug' : 'prospect', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'prospect.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'NetHunt CRM', 'slug' : 'net_hunt', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'net_hunt.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Mercury Connect', 'slug' : 'mercury_connect', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'mercury_connect.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Leadsquared', 'slug' : 'leadsquared', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'leadsquared.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Salesup', 'slug' : 'salesup', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'salesup.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Solve CRM', 'slug' : 'solve', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'solve.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Spotio', 'slug' : 'spotio', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'spotio.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'PersistIQ', 'slug' : 'persist_iq', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'persist_iq.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Bullhorn CRM', 'slug' : 'bullhorn', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'bullhorn.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, ], 'integrations' : ['zapier'] },
					{ 'name' : 'Vtiger CRM', 'slug' : 'vtiger', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'vtiger.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'CompanyHub', 'slug' : 'company_hub', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'company_hub.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'SuperOffice CRM', 'slug' : 'SuperOffice', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'SuperOffice.jpg', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'NeonCRM', 'slug' : 'neonCRM', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'neonCRM.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Referral Rock', 'slug' : 'referral_rock', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'referral_rock.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Repsly', 'slug' : 'repsly', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'repsly.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'SalesforceIQ', 'slug' : 'salesforceIQ', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'salesforceIQ.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Spiro', 'slug' : 'spiro', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'spiro.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Fastbase', 'slug' : 'fastbase', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'fastbase.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Really Simple Systems CRM', 'slug' : 'reallysimplesystems', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'reallysimplesystems.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'KonnektiveCRM', 'slug' : 'konnektiveCRM', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'konnektiveCRM.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'ClientSuccess', 'slug' : 'clientSuccess', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'clientSuccess.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Workbooks CRM', 'slug' : 'workbooks', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'workbooks.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'ProAgentWebsites.com', 'slug' : 'proAgentWebsites', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'proAgentWebsites.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'MyWifi Networks', 'slug' : 'myWifi_networks', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'myWifi_networks.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Visitor Queue', 'slug' : 'visitor_Queue', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'visitor_Queue.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'LeadBerry', 'slug' : 'leadBerry', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'leadBerry.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Salesmachine', 'slug' : 'salesmachine', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'salesmachine.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Odoo ERP Self Hosted', 'slug' : 'odoo', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'odoo.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Agent 3000', 'slug' : 'agent3000', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'agent3000.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Real Estate Webmaster', 'slug' : 'real_estate_webmaster', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'real_estate_webmaster.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Amped', 'slug' : 'amped', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'amped.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'webCRM', 'slug' : 'webCRM', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'webCRM.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'ForceManager', 'slug' : 'forceManager', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'forceManager.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Teamwave', 'slug' : 'teamwave', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'teamwave.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Attach', 'slug' : 'attach', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'attach.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Flexie CRM', 'slug' : 'flexie', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'flexie.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Upilot', 'slug' : 'upilot', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'upilot.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'FreeAgent CRM', 'slug' : 'freeAgent', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'freeAgent.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'OneLocal ReferralMagic', 'slug' : 'oneLocal_referralMagic', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'oneLocal_referralMagic.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Totango', 'slug' : 'totango', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'totango.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'RevBoss', 'slug' : 'revBoss', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'revBoss.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'CRM Connector', 'slug' : 'CRM_connector', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'CRM_connector.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Magnetic', 'slug' : 'magnetic', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'magnetic.jfif', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'CRM Connector', 'slug' : 'CRM_connector', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'CRM_connector.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'KarmaCRM', 'slug' : 'karmaCRM', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'karmaCRM.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Ally Hub', 'slug' : 'ally_hub', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'ally_hub.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Pipeliner', 'slug' : 'pipeliner', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'pipeliner.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'SalesTarget', 'slug' : 'salesTarget', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'salesTarget.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'PowrBot', 'slug' : 'powrBot', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'powrBot.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Odoo CRM', 'slug' : 'odoo_crm', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'odoo.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'ClinchPad', 'slug' : 'clinchPad', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'clinchPad.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Suite CRM', 'slug' : 'suitecrm', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'suitecrm.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier','integromat'] },
					{ 'name' : 'Teamgate CRM', 'slug' : 'teamgate', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'teamgate2.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier','integromat'] },
					{ 'name' : 'Vtiger CRM', 'slug' : 'vtiger', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'vtiger.png', 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/22514/169ea05a8180ea0db65f8021590f994d/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier','integromat'] },
					{ 'name' : 'Raynet CRM', 'slug' : 'raynetcrm', 'active' : true, 'logo_path' : 'raynetcrm.png', 'options' : [ { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : [ 'integromat'] },
				] */
				
			}
		},
		methods: {
			changeRoute( element ) {
				this.$store.state.selectedPage = element
				this.$router.push({path:'/integration/' + element})
			},

			ConnectCRM(item) {
				window.open(item.url, 'Popup', 'scrollbars=1,resizable=1,height=560,width=770');
			},
			eventCallBack(provider) {
				let that = this
				
				if(provider == 'webhook'){
					that.$router.push({path: 'destination/add'})
				}
			}
		},
		components: {
			//
		},
		computed: {
			FavoriteCrm() {
				let that = this
				
				if(that.query) {
					return that.favoriteCrm.filter((item)=>{
						return that.query.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v))
					})
				} else {
					return that.favoriteCrm;
				}
				
			}
		}
	}
</script>