<template>
    <div class="container">
        <div class="card card-default border-0" v-if="!$store.state.active || ($store.state.active && 'list destination' in $store.state.permissions)">
            <div class="card-header border-0">
				<div class="row">
					<div class="col-6 m-auto">
						<div class="r-fliter search_filter has_icon i-search add__new__sources_search">
							<i class="fa fa-search"></i>
							<div>
								<label for="search" class="sr-only">{{$t('FindYourEsp')}}</label>
								<input v-model="query" type="search" :placeholder="$t('EspPSearch')" class="form-control" id="search">
							</div>
						</div>
					</div>
					<div class="col-6">
						<div class="alert alert-oml ml-auto" style="display:none">
							<img src="/img/svg/info-circle.svg" /> {{ $t('InInfoDesc') }} <a href="javascript:void(0)">{{ $t('LetUsknow') }}</a>
						</div>
					</div>
				</div>
			</div>
			
            <div class="card-body">
                
				<div class="row text-center sourceline-item">
					<div class="col-md-3 mb-4" v-for="(item, key) in FavoriteEsp" v-bind:key="key" :id="`zapier-${item.name}`">
						<template >
							<div class="new-sources-md row m-0">
								<div class="col-md-12">
									<img src="/img/svg/By-Zapier.svg"  v-if="item.viaZapier" style="position: absolute;right: 0px;" />
									<img :src="'/img/Integration/esp/' + item.logo" style="width:60px;" />
								</div>
								<div class="col-md-12 p-0">
									<div class="m-auto">
										<label v-text="item.title" class="m-0 my-auto"></label>
										<p class="my-auto">
											{{ item.description }}
										</p>
									</div>
								</div>
								<div class="col-md-12 m-auto">
									<!-- <button class="btn btn-upgrade" v-if="!$store.state.active || ($store.state.active && 'add integrations' in $store.state.permissions)" @click="ConnectESP(item)">{{ $t('Connect') }}</button> -->
									<!-- <button class="btn btn-upgrade" @click="eventCallBack(item.name)" v-if="item.name=='webhook' || item.name=='mailchimp-native' || item.name=='sendinblue-native' || item.name=='mailjet-native'">{{ $t('Connect')}}</button> -->
									<button class="btn btn-upgrade" v-if="item.name=='webhook'">
										<router-link style="color:#fff" :to="{name: 'add-destination'}" class="no-account pull-left">{{ $t('Connect')}}</router-link>
									</button>
									
									<button class="btn btn-upgrade" v-else-if="item.name=='mailchimp-native'">
										<router-link style="color:#fff" :to="{name: 'add-mailchimp'}" class="no-account pull-left">{{ $t('Connect')}}</router-link>
									</button>
									
									<button class="btn btn-upgrade" v-else-if="item.name=='sendinblue-native'">
										<router-link style="color:#fff" :to="{name: 'add-sendinblue'}" class="no-account pull-left">{{ $t('Connect')}}</router-link>
									</button>
									
									<button class="btn btn-upgrade" v-else-if="item.name=='mailjet-native'">
										<router-link style="color:#fff" :to="{name: 'add-mailjet'}" class="no-account pull-left">{{ $t('Connect')}}</router-link>
									</button>

									<button class="btn btn-upgrade" v-else @click="ConnectESP(item)">{{ $t('Connect') }}</button>
								</div>
							</div>
						</template>
					</div>
				</div>
				
            </div>
        </div>
		<div v-if="$store.state.active && !Object.keys($store.state.permissions).includes('list destination')">
				<h5 class="sync-text">
					<img src="/img/svg/info-circle.svg" />
					<span style="text-decoration:none"> {{ $t('Noaccess') }}</span>
				</h5>
			</div>
    </div>
</template>
<script>
	// /assets/new/images/communications_logo
	export default {
		props: {
			favoriteEsp: {}
		},
		data() {
			return {
				//
				query: '',
				espname:'',
				
				/* favoriteEsp: [
					{ 
						'name' : 'Mailchimp', 'slug' : 'mailchimp', 'active' : true, 'auth_uri' : '/integrations/autoresponder/auth/mailchimp', 'logo_path' : 'mailchimp.png',
						'options' : [
							{ 'label' : 'Native', 'slug' : 'native', 'link' : '/integrations/autoresponder/auth/mailchimp', },
							{ 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' },
							{ 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' }
						], 
						'integrations' : ['native', 'zapier', 'integromat'] 
					},
					
					{ 'name' : 'SendinBlue', 'slug' : 'sendinblue', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'sendin-blue.png', 'form' : { 'sendinblue_key' : { 'placeholder' : 'SendinBlue v2 Api Key', }, }, 'endpoint' : '/api/integrations/autoresponder/sendinblue', 'options' : [ { 'label' : 'Native', 'slug' : 'native', 'link' : '#', }, { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['native', 'zapier', 'integromat'] },
					{ 'name' : 'Aweber', 'slug' : 'aweber', 'active' : true, 'auth_uri' : '/integrations/autoresponder/auth/aweber', 'logo_path' : 'aweber.png', 'options' : [ { 'label' : 'Native', 'slug' : 'native', 'link' : '/integrations/autoresponder/auth/aweber', }, { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['native', 'zapier', 'integromat'] },
					{ 'name' : 'ActiveCampaign', 'slug' : 'activecampaign', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'active-campaign.png', 'form' : { 'url' : { 'placeholder' : 'API Access URL', }, 'key' : { 'placeholder' : 'API Access KEY', }, }, 'endpoint' : '/api/integrations/autoresponder/active-campaign', 'options' : [ { 'label' : 'Native', 'slug' : 'native', 'link' : '#', }, { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['native', 'zapier', 'integromat'] },
					{ 'name' : 'Mailjet', 'slug' : 'mailjet', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'mailjet.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'endpoint' : '/api/integrations/autoresponder/mailjet', 'options' : [ { 'label' : 'Native', 'slug' : 'native', 'link' : '#', }, { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['native', 'zapier', 'integromat'] },
					{ 'name' : 'Constant Contact', 'slug' : 'constant_contact', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'constant_contact.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'ActiveTrail', 'slug' : 'activeTrail', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'activeTrail.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Microsoft Office 365', 'slug' : 'microsoft_office_365', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'microsoft_office_365.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'ConvertKit', 'slug' : 'convertKit', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'convertKit.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'GetResponse', 'slug' : 'getResponse', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'get_response.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Mailgun', 'slug' : 'mailgun', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'mailgun.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'SmartEmailing', 'slug' : 'smartEmailing', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'smartEmailing.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'MailerLite', 'slug' : 'mailerLite', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'mailerLite.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Drip', 'slug' : 'drip', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'drip.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Campaign Monitor', 'slug' : 'campaign_monitor', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'campaign_monitor.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Klaviyo', 'slug' : 'klaviyo', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'klaviyo.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'SendGrid', 'slug' : 'sendGrid', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'sendGrid.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Sendy', 'slug' : 'sendy', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'sendy.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' }, { 'label' : 'Via Integromat', 'slug' : 'integromat', 'vueRouter' : false, 'link' : 'https://www.integromat.com/en/apps/invite/827cc49b3c0304bb952484db1d29cec3' } ], 'integrations' : ['zapier', 'integromat'] },
					{ 'name' : 'Privy', 'slug' : 'privy', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'privy.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Revue', 'slug' : 'revue', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'revue.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Emma', 'slug' : 'emma', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'emma.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'EmailOctopus', 'slug' : 'emailOctopus', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'emailOctopus.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Madmini', 'slug' : 'madmini', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'madmini.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Benchmark Email', 'slug' : 'benchmark_email', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'benchmark_email.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Icontact', 'slug' : 'icontact', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'icontact.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Sendlane', 'slug' : 'sendlane', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'sendlane.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Action Network', 'slug' : 'action_network', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'action_network.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Unisender', 'slug' : 'unisender', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'unisender.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Direct Mail', 'slug' : 'direct_mail', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'direct_mail.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Curated', 'slug' : 'curated', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'curated.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Doppler', 'slug' : 'doppler', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'doppler.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'MailboxValidator', 'slug' : 'mailboxValidator', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'mailboxValidator.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Freshmail', 'slug' : 'freshmail', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'freshmail.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'SendX', 'slug' : 'sendX', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'sendX.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Kirim.Email', 'slug' : 'kirimemail', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'kirimemail.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Campayn', 'slug' : 'campayn', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'campayn.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'Newsman', 'slug' : 'newsman', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'newsman.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
					{ 'name' : 'TailoredMail', 'slug' : 'tailoredMail', 'active' : true, 'auth_uri' : '#', 'logo_path' : 'tailoredMail.png', 'form' : { 'key' : { 'placeholder' : 'API KEY', }, 'secret_key' : { 'placeholder' : 'Secret Key', }, }, 'options' : [ { 'label' : 'Via Zapier', 'slug' : 'zapier', 'vueRouter' : false, 'link' : 'https://zapier.com/developer/public-invite/20641/latest/' } ], 'integrations' : ['zapier'] },
				] */
				
			}
		},
		components: {
			//
		},
		methods: {
			ConnectESP(item) {
				window.open(item.url, 'Popup', 'scrollbars=1,resizable=1,height=560,width=770');
			},

			eventCallBack(provider) {
				let that = this
				
				if(provider == 'webhook'){
					that.espname = 'destination/add'
				}else if(provider == 'mailchimp-native'){
					that.espname = 'destination/add-mailchimp'
				}else if(provider == 'sendinblue-native'){
					that.espname = 'destination/add-sendinblue'
				}else if(provider == 'mailjet-native'){
					that.espname = 'destination/add-mailjet'
				}
			}
		},
		computed: {
			FavoriteEsp() {
				let that = this
				
				if(that.query) {
					return that.favoriteEsp.filter((item)=>{
						return that.query.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v))
					})
				} else {
					return that.favoriteEsp;
				}
				
			}
		}
	}
</script>

<style>
	
</style>